import React, { Fragment } from 'react';
import CheckboxList from '../shared/CheckboxList';
import Trigger from './Trigger';

const TriggerWriteView = props => {
  return (
    <Fragment>
      {buildWriteTriggers(
        props.data,
        props.options,
        props.changeHandler,
        props.checkboxHandler,
        props.typesSelected,
        props.deleteHandler,
      )}
    </Fragment>
  );
};

export default TriggerWriteView;

const buildWriteTriggers = (
  triggerData,
  triggerOptions,
  updateTriggersSelections,
  checkboxHandler,
  typesSelected,
  deleteHandler,
) => {
  let triggerComponents = [];
  if (!triggerOptions) return;
  
  triggerOptions.forEach((trigger, index) => {

    let alreadySelectedTriggers = triggerData.filter(selection => {
      return (
        trigger.id === selection.typeid &&
        selection.selected &&
        selection.trigger_selected
      );
    });
 
    let typeChecked = typesSelected.find(type => type.typeid === trigger.id)
      ? typesSelected.find(type => type.typeid === trigger.id).checked 
      : false;

    triggerComponents.push({
      label: (
        <Trigger
          key={index}
          type={trigger.type}
          placeholder={`Select ${trigger.type}`}
          // for 'Other', we're typing the value, so we start with ''
          value={trigger.options[0] ? trigger.options[0].trigger_name : ''}
          values={trigger.options}
          write={true}
          selected={typeChecked}
          deleteHandler={deleteHandler}
          alreadySelectedTriggers={alreadySelectedTriggers}
          changeHandler={updateTriggersSelections.bind(null, trigger.id)}
        />
      ),
      checked: typeChecked,
      changeHandler: checkboxHandler.bind(null, trigger.id), //this change handler is for the checkbox
    });
  });
  return (
    <CheckboxList
      list={triggerComponents}
      classNameOverride="trigger-list-override"
    />
  );
};