import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
//Stores
import { withTrackerDate } from '../apollo/stores/trackers';
// Components
import DateBar from '../components/DateBar';
import DietTracker from '../components/trackers/DietTracker';
import ScaleTracker from '../components/trackers/ScaleTracker';
import SkinTracker from '../components/trackers/SkinTracker';
import TreatmentTracker from '../components/trackers/TreatmentTracker';
import TriggerTracker from '../components/trackers/TriggerTracker';
import WeatherTracker from '../components/trackers/WeatherTracker';
import TrackersLoading from '../components/TrackersLoading';
import AtopicDermatitisControlTracker from '../components/trackers/ADCT/ADCTTracker';
import AtopicDermatitisControlWidget from '../components/trackers/ADCT/ADCTWidget';

//Tracker color themes
import config from '../config';
import analytics from '../libs/analytics';
// Mutations
import {
  dietTracker,
  scaleTracker,
  skinTracker,
  treatmentTracker,
  triggerTracker,
} from '../libs/graphql/mutations/trackers';
// Queries
import { DailyUserData } from '../libs/graphql/queries/data';
//TODO: We will change this GIFs from assets folder when the url is available
// GIFs
import PoScoradSeverity from '../components/shared/PoScoradSeverity';
import FlareTrackerInfo from '../components/shared/FlareTrackerInfo';

const withTreatmentMutation = graphql(treatmentTracker, {
  name: 'treatmentTracker',
});

const withScaleTrackerMutation = graphql(scaleTracker, {
  name: 'scaleTracker',
});

const withTriggerMutation = graphql(triggerTracker, {
  name: 'triggerTracker',
});

const withSkinTrackerMutation = graphql(skinTracker, { name: 'skinTracker' });

const withDietTrackerMutation = graphql(dietTracker, { name: 'dietTracker' });

const composeWithData = graphql(DailyUserData, {
  props: ({
    data: { loading, error, dailyUserData, refetch, triggerOptions },
  }) => ({
    loading,
    error,
    dailyUserData,
    triggerOptions,
    refetch,
  }),
  options: props => ({
    variables: {
      id: props.me.id,
      today:
        (props.trackerDateStore && props.trackerDateStore.currentTrackerDate) ||
        moment().format('YYYY-MM-DD'),
      readOnly:
        !props.trackerDateStore ||
        props.trackerDateStore.currentTrackerDate !==
          moment().format('YYYY-MM-DD'),
    },
    fetchPolicy: 'cache-and-network',
  }),
});

class TrackersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment().format('YYYY-MM-DD'),
      displayAlert: false,
      activeTutorialId: null,
      showADCT: false,
    };
    this.timer = null;
    this.checkDate = this.checkDate.bind(this);
    this.checkSelectedDateProps = this.checkSelectedDateProps.bind(this);
  }

  componentDidMount() {
    analytics().setCurrentScreen('track_open');
    this.resetTrackers();
    window.addEventListener('focus', this.checkDate);
    this.checkSelectedDateProps();
  }

  checkSelectedDateProps() {
    if (this.props.selectedDate) {
      this.props
        .setTrackerDate({
          variables: {
            date: `${this.props.selectedDate}`,
          },
        })
        .then(() => {
          // clear state
          window.history.pushState(null, '');
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.checkDate);
    clearTimeout(this.timer);
  }

  checkDate() {
    // this method is used to check the current date versus the date the component 'thinks' it is
    // this is meant to handle scenario where a user leaves their browser open overnight and returns to app
    // the following day. Need to do a refetch of dailyUserData. Should also reset tracker timer
    let now = moment().format('YYYY-MM-DD');
    if (now !== this.state.selectedDate && !this.props.selectedDate) {
      this.setState({
        displayAlert: true,
      });
    }
  }

  alertConfirm(e) {
    e.preventDefault();
    this.props.history.push('/refresh-dashboard');
  }

  resetTrackers() {
    // this will trigger a data refetch at midnight
    // note, if computer goes to sleep, this will not trigger but on focus listener should catch that case
    let now = moment();
    let tomorrow = moment().add(1, 'days').startOf('day');
    let timeToMidnight = tomorrow.diff(now);
    this.props
      .resetTrackerDate({
        variables: {},
      })
      .then(() => {
        this.timer = setTimeout(() => {
          this.checkDate();
        }, timeToMidnight);
      });
  }

  toggleADCTVisibility() {
    this.setState(prevState => ({
      showADCT: !prevState.showADCT
    }));
  }

  render() {
    if (!this.props.trackerDateStore) return null;

    const { triggerOptions, dailyUserData } = this.props;
    const selectedDate = this.props.trackerDateStore.currentTrackerDate;
    const ADCTLastDayRegistered = this.props.dailyUserData?.adctData?.most_recent_save_date; 

    const skinData = !dailyUserData?.skinData ? {} : { ...dailyUserData?.skinData, ...(dailyUserData?.pictures && { pictures: dailyUserData.pictures }) };

    return (
      <div
        className={
          'health-tracker-main' +
          (moment().diff(selectedDate, 'days') < 1
            ? ''
            : ' health-tracker-main__past')
        }>
        {this.state.displayAlert ? (
          <div className="alert-reset">
            <div className="alert-reset__body">
              <h2 className="title__large title__cobalt title__semibold">
                It's a new day!
              </h2>
              <h4 className="headers headers__semibold">
                You need to reload your trackers.
              </h4>
              <button
                className="button button__secondary100 button-rectangle-small"
                onClick={this.alertConfirm.bind(this)}>
                Reload
              </button>
            </div>
          </div>
        ) : null}
        {this.state.showADCT ? null : (
          <DateBar
            date={selectedDate}
            changeTrackerDate={this.props.changeTrackerDate}
          />
        )}
        
        {this.props.loading ? (
          <TrackersLoading />
        ) : this.state.showADCT ? (
              <AtopicDermatitisControlTracker 
              userId={this.props.me.id} 
              date={selectedDate}
              onClose={() => {
                this.setState({
                  showADCT: false,
                });
              }}/>
        ) : (
          <div className="health-tracker health-tracker__container">
            <div className="tracker-buttons">
              <PoScoradSeverity
                date={selectedDate}
                skinData={
                  this.props.dailyUserData &&
                  this.props.dailyUserData?.skinData !== null
                }
                itchData={
                  this.props.dailyUserData &&
                  this.props.dailyUserData?.itchData !== null &&
                  this.props.dailyUserData?.itchData?.level !== null
                }
                sleepData={
                  this.props.dailyUserData &&
                  this.props.dailyUserData?.sleepData !== null &&
                  this.props.dailyUserData?.sleepData?.level !== null
                }
                poscoradData={
                  this.props.dailyUserData &&
                  this.props.dailyUserData?.poscoradData
                }
                userId={this.props.me.id}              
              />
              <FlareTrackerInfo userId={this.props.me.id} date={selectedDate} />
              {
                (!ADCTLastDayRegistered || (ADCTLastDayRegistered && moment(ADCTLastDayRegistered) < moment().subtract(30, 'days'))) &&
                <AtopicDermatitisControlWidget toggleADCTVisibility={this.toggleADCTVisibility.bind(this)} />

              }
            </div>
            <div
              className={`health-tracker__grid`}>
              <h2 className="health-tracker__group-title">Symptoms</h2>
              <SkinTracker
                theme={{
                  title: 'skin',
                  color: config.trackerTheme.skin,
                  directionText: 'Skin tracker direction text',
                  instructionsText:
                    'This tool tracks the severity and location of your eczema over 48 hours. Click on the body graphic where your eczema appears. Next, rate the severity of your skin symptoms. In the final screen you can track your skin infections and enter notes.',
                  class: 'card__width-2',
                }}
                id={this.props.me.id}
                icon="Skin"
                date={selectedDate}
                data={skinData}
                changeHandler={this.props.skinTracker}
              />
              <ScaleTracker
                theme={{
                  title: 'itch',
                  color: config.trackerTheme.itch,
                  directionText: 'What is your itch level?',
                  instructionsText:
                    'This tool tracks your average itch level over a 48-hour period. Click on the scale or the forward/back arrows to rate your average level of itch from 0-10.',
                  readText: 'My itch level',
                  helpText: 'Select your level in the past 48 hours',
                  helpInstructionText:
                    '0 = no itch ,  10 = worst itch imaginable',
                }}
                id={this.props.me.id}
                icon="Itch"
                date={selectedDate}
                data={
                  !this.props.dailyUserData
                    ? {}
                    : this.props.dailyUserData.itchData?.level !== null
                    ? this.props.dailyUserData.itchData
                    : {}
                }
                changeHandler={this.props.scaleTracker}
              />
              <ScaleTracker
                theme={{
                  title: 'sleep',
                  color: config.trackerTheme.sleep,
                  directionText: 'How much sleep disturbance?',
                  instructionsText:
                    'This tool tracks your average quality of sleep over a 48-hour period. Click on the scale or the forward/back arrows to rate your average quality of sleep from 0-10.',
                  readText: 'My sleep quality',
                  helpText: 'Select your level in the past 48 hours',
                  helpInstructionText:
                    '0 = no trouble, 10 = a lot of trouble sleeping',
                }}
                id={this.props.me.id}
                icon="Sleep"
                date={selectedDate}
                data={
                  !this.props.dailyUserData
                    ? {}
                    : this.props.dailyUserData.sleepData?.level !== null
                    ? this.props.dailyUserData.sleepData
                    : {}
                }
                changeHandler={this.props.scaleTracker}
              />
              <ScaleTracker
                theme={{
                  title: 'pain',
                  color: config.trackerTheme.pain,
                  directionText: 'What is your pain level?',
                  instructionsText:
                    'This tool tracks your average skin pain level over a 48-hour period. Click on the scale or the forward/back arrows to rate your average level of skin pain from 0-10.',
                  readText: 'My pain level',
                  helpText: 'Select your level in the past 48 hours',
                  helpInstructionText:
                    '0 = no pain, 10 = worst pain imaginable',
                }}
                id={this.props.me.id}
                icon="Pain"
                date={selectedDate}
                data={
                  !this.props.dailyUserData
                    ? {}
                    : this.props.dailyUserData.painData?.level !== null
                    ? this.props.dailyUserData.painData
                    : {}
                }
                changeHandler={this.props.scaleTracker}
              />
            </div>
            <div className="health-tracker__grid">
              <h2 className="health-tracker__group-title">Treatments</h2>
              <TreatmentTracker
                theme={{
                  title: 'treatment',
                  color: config.trackerTheme.treatment,
                  directionText: 'Check all that apply',
                  instructionsText:
                    'This tool tracks your eczema regimen. Click on the treatments you did today and what time of day you did them (morning, afternoon, evening, bedtime). To update your Treatment Tracker, click on the settings icon at the top of the card, or click on Settings page link in the drop down menu at the top of the page.',
                  class: 'card__width-2',
                }}
                id={this.props.me.id}
                icon="Treatment"
                data={
                  !this.props.dailyUserData
                    ? []
                    : (this.props.dailyUserData.treatmentData &&
                        this.props.dailyUserData.treatmentData.data) ||
                      []
                }
                treatments={
                  !this.props.loading && this.props.me.profile
                    ? this.props.me.profile.userTreatments
                    : []
                }
                changeHandler={this.props.treatmentTracker}
                notes={
                  !this.props.dailyUserData
                    ? ''
                    : (this.props.dailyUserData.treatmentData &&
                        this.props.dailyUserData.treatmentData.notes) ||
                      ''
                }
                date={selectedDate}
                settings={true}
              />
            </div>
            <div className="health-tracker__grid">
              <h2 className="health-tracker__group-title">Triggers</h2>

              <ScaleTracker
                theme={{
                  title: 'stress',
                  color: config.trackerTheme.stress,
                  directionText: 'How stressful was your day?',
                  instructionsText:
                    'This tool tracks your average stress level over a 48-hour period. Click on the scale or the forward/back arrows to rate your average level of stress from 0-10. Stress is a known trigger for eczema.',
                  readText: 'My stress level',
                  helpText: 'Select your level in the past 48 hours',
                  helpInstructionText:
                    '0 = no stress, 10 = worst stress imaginable',
                }}
                id={this.props.me.id}
                icon="Stress"
                data={
                  !this.props.dailyUserData
                    ? {}
                    : this.props.dailyUserData.stressData?.level !== null
                    ? this.props.dailyUserData.stressData
                    : {}
                }
                date={selectedDate}
                changeHandler={this.props.scaleTracker}
              />
              <DietTracker
                theme={{
                  title: 'diet',
                  color: config.trackerTheme.diet,
                  directionText: 'I ate food with:',
                  instructionsText:
                    'This tool tracks the foods that are considered highly allergenic and if you came into contact with one or more of them through eating, touching or breathing. You can also track foods that are not on the list. This tool also tracks number of glasses of water you consumed, which contributes to healthy skin.',
                }}
                id={this.props.me.id}
                favoriteFoods={this.props.me.profile.favoriteFoods}
                icon="Diet"
                date={selectedDate}
                data={
                  !this.props.dailyUserData
                    ? {}
                    : this.props.dailyUserData.dietData || {}
                }
                changeHandler={this.props.dietTracker}
              />
              <TriggerTracker
                theme={{
                  title: 'triggers',
                  color: config.trackerTheme.trigger,
                  directionText: 'Check if you were exposed to:',
                  instructionsText:
                    'This tool tracks any environmental triggers you may have been exposed to over a 48-hour period. This list includes all known triggers for eczema plus a place to add your own. Eczema triggers are different for everyone so some of the triggers listed may not apply to you.',
                }}
                triggerOptions={triggerOptions}
                id={this.props.me.id}
                icon="Triggers"
                date={selectedDate}
                data={
                  !this.props.dailyUserData
                    ? []
                    : this.props.dailyUserData.triggerData
                }
                changeHandler={this.props.triggerTracker}
              />
            </div>
            <div className="health-tracker__grid no-padding">
              <WeatherTracker
                theme={{
                  title: 'weather',
                }}
                id={this.props.me.id}
                date={selectedDate}
                data={
                  !this.props.dailyUserData
                    ? {}
                    : this.props.dailyUserData.weatherData
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withTrackerDate,
  composeWithData,
  withTreatmentMutation,
  withTriggerMutation,
  withScaleTrackerMutation,
  withSkinTrackerMutation,
  withDietTrackerMutation,
  withRouter,
)(TrackersContainer);
